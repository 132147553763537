<template>
  <div class="flex bg-blue-50 overflow-hidden dark:bg-gray-700">
    <!-- Sidebar -->
    <Sidebar 
      :loadingDashboard="loadingDashboard"
     />

    <!-- Main Content -->
    <div class="flex flex-wrap w-full h-full">
      <!-- Card -->
      <div 
        v-for="service_info in all_services_info" 
        :key="service_info.service_name"
        class="w-full sm:w-1/2 lg:w-1/4 h-1/4 animate__animated animate__flipInX"
      >
        <div class="max-w-sm rounded-lg bg-blue-100 text-left m-10 dark:bg-gray-800 ">
          <div class="px-6 py-4">
            <a class="font-bold text-xl mb-2 flex text-sky-700 group dark:text-gray-300" :href="service_info.link" target="_blank">
              <span class="group-hover:text-sky-700 transition-colors">{{ service_info.service_name }}</span>
              <i class="material-symbols--link text-blue-100 ml-1 group-hover:text-sky-700 transition-colors dark:text-gray-800"></i>
            </a>
            <p class="text-gray-600 text-sm dark:text-gray-400">
              {{ service_info.description }}
            </p>
          </div>
          <div class="px-6 pt-4 pb-2">
            <span 
              v-for="tag in service_info.tags" 
              :key="tag"
              class="inline-block bg-blue-200 rounded-full px-3 py-1 text-sm font-semibold text-sky-700 mr-2 mb-2 transition-colors hover:text-blue-100 hover:bg-sky-700 cursor-default dark:bg-gray-700 dark:text-gray-400 dark:hover:text-gray-200 dark:hover:bg-gary-500"
            >
              #{{ tag }}
            </span>
          </div>
        </div>
      </div>
      
    </div>

  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue';  // 导入 Sidebar 组件
import api from '../api'; // 导入封装好的API模块
import 'animate.css';

export default {
  name: "DashboardPage",
  data() {
    return {
      all_services_info: [],
      loadingDashboard: false,
    };
  },
  components: {
    Sidebar,  // 注册 Sidebar 组件
  },
  methods: {
    async loadStatus() {
      // loading 
      this.loadingDashboard = true;
      
      // get status
      var data = {
        token: this.$store.state.token
      }
      const response = await api.getStatus(data);
      
      if (response.data.result && response.data.data.length > 0) {
        if (response.data.data.length > 0) {
          this.all_services_info = response.data.data; // 更新数据
        } else {
          console.log("data length: 0");
          this.all_services_info = [];
          alert('未查询到相关数据');
        }
      } else {
        // logout
        this.$router.push({ name: 'LandingPage' });
      }
      this.loadingDashboard = false;
    },
  },
  mounted() {
    this.loadStatus();
    //animated duration
    document.documentElement.style.setProperty('--animate-duration', '1s');
  },
};
</script>

<style src="../style/icons.css">
.material-icons {
  font-size: 24px;
}

</style>