<template>
  <div class="flex overflow-hidden">
    <!-- Sidebar -->
    <Sidebar 
      :loadingStatus="loadingStatus"
     />

    <!-- Main Content -->
    <div class="flex flex-wrap w-full bg-blue-50 dark:bg-gray-700">
      <div class="flex-1 pt-6 ml-20 mr-20 space-y-4">
        <div class="flex items-center mb-4 mt-4">
          <div class="relative flex-1">
            <!-- 搜索框 -->
            <input
              class="flex h-10 rounded-md border-2 border-input bg-blue-50 px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground placeholder:text-gray-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 pl-4 w-full dark:bg-gray-700 dark:text-gray-200 dark:border-gray-600 dark:placeholder:text-gray-300 dark:focus-visible:ring-gray-500 dark:focus-visible:ring-offset-gray-500 "
              placeholder="搜索关键词..."
              type="search"
              v-model="searchQuery"
            />
          </div>
          <!-- 排序按钮 -->
          <div class="relative ml-4">
            <button
              @click="isDropdownOpen = !isDropdownOpen"
              class="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2 dark:text-gray-300 dark:border-gray-600 dark:placeholder:text-gray-300"
            >
              排序
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="h-4 w-4 ml-2"
              >
                <path d="m21 16-4 4-4-4"></path>
                <path d="M17 20V4"></path>
                <path d="m3 8 4-4 4 4"></path>
                <path d="M7 4v16"></path>
              </svg>
            </button>
            <!-- 排序选项 -->
            <div v-if="isDropdownOpen" class="absolute right-0 mt-2 bg-gray-100 shadow-lg rounded-md z-10 border border-gray-300 dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700">
              <ul class="text-left w-32">
                <li
                  class="text-sm cursor-pointer px-4 py-2 hover:bg-gray-200 text-gray-700 dark:hover:bg-gray-600 dark:text-gray-300"
                  @click="toggleSort('service_name')"
                ><i class="material-symbols--list-alt align-middle mr-1"></i>服务名称</li>
                <li
                  class="text-sm cursor-pointer px-4 py-2 hover:bg-gray-200 text-gray-700 dark:hover:bg-gray-600 dark:text-gray-300"
                  @click="toggleSort('status')"
                ><i class="pajamas--status-health align-middle mr-1"></i>运行状态</li>
              </ul>
            </div>
          </div>
        </div>

        <!-- 表格 -->
        <div class="relative rounded-lg">
          <table class="w-full text-sm text-left text-gray-500 bg-blue-100 dark:text-gray-400 table-auto dark:bg-gray-800">
            <thead class="text-sky-700 dark:bg-gray-800 dark:text-gray-300">
              <tr calss="mb-2">
                <th scope="col" class="px-6 py-2"><i class="material-symbols--cloud mr-1 align-middle"></i>服务名称</th>
                <th scope="col" class="px-4 py-2"><i class="material-symbols--description mr-1 align-middle"></i>描述</th>
                <th scope="col" class="px-4 py-2"><i class="mdi--tag mr-1 align-middle"></i>标签</th>
                <th scope="col" class="px-4 py-2"><i class="pajamas--status-health mr-1 align-middle"></i>运行状态</th>
                <th scope="col" class="px-4 py-2"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="this.all_services_info.length === 0">
                <td colspan="6" class="px-4 py-4 text-center text-sky-700 font-semibold dark:text-gray-300">
                  数据载入中
                  <i class="three-dots-loading" style="vertical-align: middle;" />
                </td>
              </tr>
              <tr v-for="service_info in sortedInfo" :key="service_info.service_name">
                <th scope="row" :class="['px-6 py-2 font-semibold text-sky-700 whitespace-nowrap dark:text-gray-300', animationClass]">{{ service_info.service_name }}</th>
                <td :class="['px-4 py-2 text-gray-700 dark:text-gray-300 text-sm', animationClass]">{{ service_info.description }}</td>
                <td :class="['px-4 py-2', animationClass]">
                  <span
                    v-for="tag in service_info.tags"
                    :key="tag"
                    class="inline-block bg-blue-200 rounded-full px-3 py-1 text-sm font-semibold text-sky-700 mr-2 mb-1 mt-1 hover:text-blue-100 hover:bg-sky-700 cursor-pointer dark:bg-gray-600 dark:text-gray-300 dark:hover:text-gray-100 dark:hover:bg-gray-500"
                    @click="addTagToSearch(tag)"
                  >#{{ tag }}</span>
                </td>
                <td :class="['px-4 py-2', animationClass]" style="vertical-align: middle;">
                  <div class="flex items-center">
                    <i v-if="service_info.status === '正常'" class="icon-park-solid--round text-emerald-500 mr-2"></i>
                    <i v-else-if="service_info.status === '异常'" class="icon-park-solid--round text-rose-400 mr-2"></i>
                    <i v-else-if="service_info.status === '维护'" class="icon-park-solid--round text-amber-500 mr-2"></i>
                    <span class="text-cyan-800 dark:text-gray-300">{{ service_info.status }}</span>
                  </div>
                </td>
                <td 
                  :class="['px-4 py-2 text-gray-500', animationClass]"
                  style="vertical-align: middle;"
                >
                  <div class="flex items-center">
                    <a
                      type="button"
                      class="mdi--bug mr-1 hover:text-amber-600 hover:cursor-pointer"
                      title="报告问题"
                      @click="ReportIssue(service_info.service_name)"
                    ></a>
                    <a
                      type="button"
                      class="material-symbols--link"
                      title="转到链接"
                      :href="service_info.link"
                      target="_blank"
                    ></a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

        </div>

        <!-- 分页控制 -->
        <div class="flex justify-between items-center mt-4 mb-2 text-xs text-gray-400">
          <span>
            <span>{{ this.updateTime }}
              <!-- <i 
                id="refresh_icon" 
                :class="['fa--refresh ml-2 cursor-pointer', { rotate: iconRotating, 'text-blue-700': iconRotating }]" 
                title="点击刷新" 
                @click="loadStatus()"
              ></i> -->
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
  <ReportIssueModal 
    :isOpen="isModalOpen" 
    :content="modalContent" 
    @close="handleCloseModal"
  />
</template>

<script>
import Sidebar from '@/components/Sidebar.vue'; // 导入 Sidebar 组件
import api from '../api'; // 导入封装好的API模块
import ReportIssueModal from '@/components/ModalReport.vue';
import 'animate.css';

export default {
  name: "OperationStatus",
  components: {
    Sidebar, // 注册 Sidebar 组件
    ReportIssueModal, // 注册 ReportIssueModal 组件
  },
  data() {
    return {
      all_services_info: [],
      updateTime: '',
      searchQuery: '',
      isDropdownOpen: false,
      sortByField: '', // 用于排序的字段
      isAscending: true, // 当前排序顺序，true 为正序，false 为反序
      //iconRotating: false,
      animationClass: 'animate__animated animate__fadeInRight', //动画
      loadingStatus: false,
      isModalOpen: false,
      modalContent: ''
    };
  },
  computed: {
    filteredPatchInfo() {
      return this.all_services_info.filter(info => {
        return (
          info.service_name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          info.description.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          info.status.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          info.tags.some(tag => tag.toLowerCase().includes(this.searchQuery.toLowerCase()))
        );
      });
    },
    sortedInfo() {
      return this.sortInfo(this.filteredPatchInfo);
    },
  },
  methods: {
    async loadStatus() {
      // set loading
      this.loadingStatus = true;
      // icon rotation
      //this.iconRotating = true;
      // get status
      var data = {
        token: this.$store.state.token
      }
      const response = await api.getStatus(data);

      if (response.data.result && response.data.data.length > 0) {
        if (response.data.data.length > 0) {
          this.searchQuery = '';
          this.all_services_info = response.data.data; // 更新数据
          this.updateTime = "更新时间：" + response.data.update_time;
        } else {
          console.log("data length: 0");
          this.all_services_info = [];
          alert('未查询到相关数据');
        }
      } else {
        // logout
        this.$router.push({ name: 'LandingPage' });
      }
      // disabled rotation
      //this.iconRotating = false;
      this.loadingStatus = false;
    },
    sortInfo(patchInfo) {
      if (this.sortByField === '') return patchInfo;
      const sorted = patchInfo.slice().sort((a, b) => {
        if (this.sortByField === 'status') {
          const statusOrder = { '正常': 1, '异常': 2 }; // 定义状态的排序顺序
          return statusOrder[a.status] - statusOrder[b.status];
        } else {
          return a[this.sortByField].localeCompare(b[this.sortByField]);
        }
      });
      // 根据 isAscending 的值返回正序或反序
      return this.isAscending ? sorted : sorted.reverse();
    },
    addTagToSearch(tag) {
      this.searchQuery = tag; // 将标签内容设置为搜索栏内容
    },
    toggleSort(field) {
      if (this.sortByField === field) {
        // 如果再次点击同一字段，则切换排序顺序
        this.isAscending = !this.isAscending;
      } else {
        // 否则按新字段正序排序
        this.sortByField = field;
        this.isAscending = true;
      }
      this.isDropdownOpen = false; // 关闭下拉菜单
    },
    ReportIssue(service_name) {
      this.modalContent=service_name;
      this.openModal();
    },
    openModal() {
      this.isModalOpen = true;
    },
    handleCloseModal() {
      this.isModalOpen = false;
    },
  },
  mounted() {
    this.loadStatus();
    document.documentElement.style.setProperty('--animate-duration', '.5s');
  },
};
</script>

<style src="../style/icons.css">
.material-icons {
  font-size: 24px;
}

.animation_table {
  animation: bounce;
  animation-duration: 2s;
  display: inline-block;
}
</style>