<template>
  <aside 
    class="sticky top-0 h-screen w-56 bg-blue-100 text-gray-800 p-4 dark:bg-gray-800 dark:text-gray-200 flex flex-col"
    >
    <div class="flex items-center mb-4 space-x-1">
      <span 
        class="text-lg font-bold cursor-default text-sky-700 dark:text-sky-600" 
        href="#" 
        id="title">
        logbase.cloud
      </span>
    </div>
    <nav class="space-y-2">
      <router-link to="/dashboard" exact v-slot="{ href, navigate, isActive }">
          <div
              :class="['w-full flex items-center space-x-2 py-2 px-2 rounded-lg', { 'bg-blue-200 text-sky-700 dark:bg-gray-700 dark:text-gray-200': isActive, 'hover:bg-sky-100 text-gray-500 dark:hover:bg-gray-700 dark:active:bg-gray-700 dark:text-gray-400': !isActive }]"
              :href="href"
              @click="navigate"
          >
              <i class="w-4 h-4 material-symbols--list-alt" />
              <span class="text-sm font-medium">服务总览</span>
              <i v-if="loadingDashboard" class="icon--loading custom-spin ml-2"></i>
          </div>
      </router-link>
      <router-link to="/status" exact v-slot="{ href, navigate, isActive }">
          <div
              :class="['w-full flex items-center space-x-2 py-2 px-2 rounded-lg', { 'bg-blue-200 text-sky-700 dark:bg-gray-700 dark:text-gray-200': isActive, 'hover:bg-sky-100 text-gray-500 dark:hover:bg-gray-700 dark:active:bg-gray-700 dark:text-gray-400': !isActive }]"
              :href="href"
              @click="navigate"
          >
              <i class="w-4 h-4 pajamas--status-health" />
              <span class="text-sm font-medium">运行状态</span>
              <i v-if="loadingStatus" class="icon--loading custom-spin ml-2"></i>
          </div>
      </router-link>
      <router-link to="/tools" exact v-slot="{ href, navigate, isActive }">
          <div
              :class="['w-full flex items-center space-x-2 py-2 px-2 rounded-lg', { 'bg-blue-200 text-sky-700 dark:bg-gray-700 dark:text-gray-200': isActive, 'hover:bg-sky-100 text-gray-500 dark:hover:bg-gray-700 dark:active:bg-gray-700 dark:text-gray-400': !isActive }]"
              :href="href"
              @click="navigate"
          >
              <i class="w-4 h-4 material-symbols-light--box" />
              <span class="text-sm font-medium">常用工具</span>
          </div>
      </router-link>
    </nav>
    <!-- Icons section at the bottom -->
    <div class="flex justify-between mt-auto ml-2 mr-6">
      <!-- Dark Mode Switch -->
      <div id="darkmode_switch" class="cursor-pointer" @click="toggleDarkMode">
        <i :class="isDarkMode ? 'ph--moon-bold' : 'ph--sun-bold'" 
           class="text-gray-600 hover:text-yellow-600 transition-colors dark:text-white dark:hover:text-orange-500"></i>
      </div>

      <!-- About -->
      <div id="about_btn" class="cursor-pointer" @click="about">
        <i class="dashicons--info-outline text-gray-600 hover:text-blue-700 transition-colors dark:text-white dark:hover:text-blue-500"></i>
      </div>  

      <!-- Logout -->
      <div id="logout_btn" class="cursor-pointer" @click="logout">
        <i class="ion--log-out-outline text-gray-600 hover:text-red-600 transition-colors dark:text-white dark:hover:text-red-500"></i>
      </div>
    </div>
  </aside>
  <InfoModal 
    :isOpen="isModalOpen" 
    :title="modalTitle" 
    :content="modalContent" 
    @close="handleCloseModal"
  />
</template>

<script>
import 'animate.css';
import InfoModal from '@/components/ModalInfo.vue';

export default {
  name: "TheSidebar",
  components: {
    InfoModal,
  },
  data() {
    return {
      isDarkMode: localStorage.getItem('theme') === 'dark',
      isModalOpen: false,
      modalTitle: '',
      modalContent: '',
    }
  },
  props: {
    loadingDashboard: {
      type: Boolean,
      default: false
    },
    loadingStatus: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggleDarkMode() {
      this.isDarkMode = !this.isDarkMode;
      if (this.isDarkMode) {
        document.documentElement.classList.add('dark');
        localStorage.setItem('theme', 'dark'); // 保存用户选择
      } else {
        document.documentElement.classList.remove('dark');
        localStorage.setItem('theme', 'light');
      }
    },
    logout() {
      this.$router.push('/login');
    },
    about() {
      var title = "联系作者";
      var content = "zhenglinxiao@logbase.cn";
      this.isModalOpen = true;
      this.modalTitle = title;
      this.modalContent = content;
    },
    handleCloseModal() {
      this.isModalOpen = false;
    }
  },
  mounted() {
    this.isDarkMode = localStorage.getItem('theme') === 'dark';
    if (this.isDarkMode) {
      document.documentElement.classList.add('dark');
    }
  }
};
</script>

<style>
.custom-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


</style>
