<template>
  <transition
    name="modal-fade"
    @leave="leave"
  >
  <div v-if="isOpen" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-40 dark:bg-white dark:bg-opacity-10">
      <!-- 你的模态框内容 -->
      <div id="modal_window" class="bg-gray-100 pt-5 pl-8 pr-8 pb-4 rounded-lg shadow-lg w-3/4 lg:w-1/5 md:w-1/3 animate__animated animate__zoomIn dark:bg-gray-800" @click.stop>
        <h2 class="text-lg mb-4 text-gray-600 font-bold text-left dark:text-gray-300">
          <i class="material-symbols--note text-md" style="vertical-align: middle; line-height: 1;" />
          Base64加解密
        </h2>
        <div class="relative mb-2 text-left text-gray-500 dark:text-gray-300">
          <!-- input area -->
          <textarea 
            v-model="input_data" 
            rows="5"
            class="w-full p-2 pr-8 rounded-md bg-gray-100 border-gray-500 border-2 dark:bg-gray-700 dark:text-gray-100 resize-none overflow-hidden" 
            placeholder="请输入...">
          </textarea>
          
          <!-- 复制图标 -->
          <i 
            class="ph--copy absolute top-2 right-2 cursor-pointer text-gray-500 dark:text-gray-300 hover:text-gray-700 dark:hover:text-gray-100" 
            @click="copyToClipboard"
            title="复制内容"
          >
            content_copy
          </i>

        </div>
        <div class="flex justify-end space-x-2">
          <!-- 复制成功的提示 -->
          <span v-if="copySuccess" class="absolute left-10 text-sm text-gray-500 dark:text-gray-300">✔复制成功</span>
          <button 
            @click="base64_encode" 
            class="px-4 py-1.5 rounded bg-blue-600 text-gray-100 bg-opacity-90 hover:bg-blue-700 hover:text-gray-50 dark:bg-sky-700 dark:hover:bg-sky-600 dark:text-gray-100"
          >
            加密
          </button>
          <button 
            @click="base64_decode" 
            class="px-4 py-1.5 rounded bg-blue-600 text-gray-100 bg-opacity-90 hover:bg-blue-700 hover:text-gray-50 dark:bg-sky-700 dark:hover:bg-sky-600 dark:text-gray-100"
          >
            解密
          </button>
          <button @click="closeModal" class="px-4 py-1.5 rounded bg-gray-500 bg-opacity-90 text-gray-100 hover:bg-gray-600 hover:text-gray-50">关闭</button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import api from '../api'; // 导入封装好的API模块
import 'animate.css';

export default {
  name: 'ModalBase64',
  data() {
    return {
      input_data: '',
      copySuccess: false,
    };
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    }
  },
  methods: {
    closeModal() {
      this.input_data = ''; // 清空输入框内容
      this.$emit('closeModal');
    },
    async base64_encode() {
      if (!this.input_data) {
        return;
      }
      var data = {
        token: this.$store.state.token,
        data: this.input_data
      }
      const response = await api.base64_encode(data);
      if (response.data.result) {
        this.input_data = response.data.data;
      } else {
        this.input_data = '[加密失败]';
      }
    },
    async base64_decode() {
      if (!this.input_data) {
        return;
      }
      var data = {
        token: this.$store.state.token,
        data: this.input_data
      }
      const response = await api.base64_decode(data);
      if (response.data.result) {
        this.input_data = response.data.data;
      } else {
        this.input_data = '[解密失败]';
      }
    },
    leave(el) {
      const modal_element = el.querySelector('#modal_window'); // 声明并获取 modal_element
      if (modal_element) {
        modal_element.classList.remove('animate__zoomIn');
        modal_element.classList.add('animate__zoomOut');
      }
    },
    // 复制到剪贴板
    copyToClipboard() {
      if (this.input_data) {
        navigator.clipboard.writeText(this.input_data).then(() => {
          this.copySuccess = true; // 显示复制成功提示
        setTimeout(() => {
          this.copySuccess = false; // 2秒后隐藏提示
        }, 2000);
      }).catch(err => {
        console.error('复制失败:', err);
      });
      }
    },
    mounted() {
    }
  }
};
</script>


<style scoped>
.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s;
}
.modal-fade-enter, .modal-fade-leave-to /* .modal-fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.fixed {
  z-index: 9999; /* 确保背景在模态框下 */
}
</style>
