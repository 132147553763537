<template>
  <div class="flex bg-blue-50 dark:bg-gray-700 overflow-hidden">
    <!-- Sidebar -->
    <Sidebar 
      :loadingDashboard="loadingDashboard"
     />

    <!-- Main Content -->
    <div class="flex flex-wrap w-full h-full">
      <!-- example card -->
      <div class="w-full sm:w-1/2 lg:w-1/4 h-1/4 animate__animated animate__flipInX">
        <div class="max-w-sm bg-blue-100 text-left m-10 dark:bg-gray-800 dark:border-none">
          <div class="px-6 py-4">
            <a 
              class="font-bold text-xl mb-2 text-sky-800 hover:text-rose-500 dark:text-gray-300 dark:hover:text-rose-500 cursor-pointer"
              @click="openModal('Base64')"
              >base64</a>
            <p class="text-gray-500 dark:text-gray-400 text-base">
              加解密Base64
            </p>
          </div>
        </div>
      </div>

    </div>

    <!-- ModalBase64 -->
    <ModalBase64 
      :isOpen="modalOpen_base64" 
      @closeModal="closeModal" 
    />
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue';  // 导入 Sidebar 组件
import ModalBase64 from '@/components/ModalBase64.vue';  // 导入 ModalBase64 组件
import 'animate.css';

export default {
  name: "ToolsPage",
  data() {
    return {
      modalOpen_base64: false,
    };
  },
  components: {
    Sidebar,  // 注册 Sidebar 组件
    ModalBase64,
  },
  methods: {
    openModal(title) {
      if (title == 'Base64') {
        this.modalOpen_base64 = true;
      }
    },
    closeModal() {
      this.modalOpen_base64 = false;
    },
  },
  mounted() {
    //animated duration
    document.documentElement.style.setProperty('--animate-duration', '1s');
  },
};
</script>

<style src="../style/icons.css">
.material-icons {
  font-size: 24px;
}

</style>
