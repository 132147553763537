import { createRouter, createWebHistory } from 'vue-router';
import LandingPage from '@/components/LandingPage.vue';
import MainPage from '@/components/MainPage.vue';
import DashboardPage from '@/components/Dashboard.vue';
import OperationStatus from '@/components/OperationStatus.vue';
import AboutPage from '@/components/About.vue';
import ToolsPage from '@/components/Tools.vue';

const routes = [
  {
    path: '/',
    name: 'MainPage',
    component: MainPage
  },
  {
    path: '/login',
    name: 'LandingPage',
    component: LandingPage
  },
  {
    path: '/dashboard',
    name: 'DashboardPage',
    component: DashboardPage
  },
  {
    path: '/status',
    name: 'OperationStatus',
    component: OperationStatus
  },
  {
    path: '/about',
    name: 'AboutPage',
    component: AboutPage
  },
  {
    path: '/tools',
    name: 'ToolsPage',
    component: ToolsPage
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

// Set the document title dynamically based on the route's meta title
router.beforeEach((to, from, next) => {
  document.title = 'logbase.cloud';
  next();
});

export default router;

