<template>
  <div class="flex flex-col items-center justify-center min-h-screen bg-gray-50 dark:bg-slate-800">
    <div class="w-full sm:w-1/2 lg:w-1/4 max-w-xs rounded-lg animate__animated animate__flipInX">
      <div class="text-left">
        <div class="px-6 py-2">
          <h2 class="font-bold text-2xl mb-4 mt-2 text-gray-600 hover:text-blue-800 transition-colors text-center dark:text-sky-600 dark:hover:text-sky-500">logbase.cloud</h2>
          <form @submit.prevent="handleSubmit">
            <div class="flex items-center mb-4">
              <input 
                class="text-gray-600 text-sm flex-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-offset-0 focus:ring-sky-500 dark:bg-slate-700 dark:text-white dark:border-gray-600" 
                id="account" 
                v-model="form.account"
                placeholder="账号"
                @keydown="handleKeyDown"
              />
            </div>

            <div class="flex items-center mb-4">
              <input 
                class="text-gray-600 text-sm flex-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-offset-0 focus:ring-sky-500 dark:bg-slate-700 dark:text-white dark:border-gray-600" 
                id="password" 
                type="password" 
                v-model="form.password"
                placeholder="密码"
                @keydown="handleKeyDown"
              />
            </div>
          </form>
        </div>
        <div class="px-6 text-center">
          <button 
            class="inline-flex items-center justify-center w-full h-10 px-4 py-2 bg-blue-500 hover:bg-blue-400 text-white font-bold border-b-4 border-blue-700 hover:border-blue-500 rounded focus:outline-none focus:ring-1 focus:ring-offset-0 focus:ring-sky-500 dark:bg-sky-700 dark:hover:bg-sky-600 dark:border-sky-900 dark:hover:border-sky-700"
            id="submit_button"
            @click="handleSubmit"
          >
            登&nbsp;录
          </button>
        </div>
      </div>
    </div>
  </div>
  
  <!-- Dark Mode Switch -->
  <div id="darkmode_switch" class="darkmode-switch" @click="toggleDarkMode">
    <i :class="isDarkMode ? 'ph--moon-bold' : 'ph--sun-bold'" 
       class="text-gray-600 hover:text-orange-700 transition-colors dark:text-white dark:hover:text-orange-500"></i>
  </div>
  
  <InfoModal 
    :isOpen="isModalOpen" 
    :title="modalTitle" 
    :content="modalContent" 
    @close="handleCloseModal"
  />
</template>

<script>
import InfoModal from '@/components/ModalInfo.vue';

export default {
  name: "LandingPage",
  components: {
    InfoModal, // 注册 InfoModal 组件
  },
  data() {
    return {
      form: {
        account: '',
        password: '',
      },
      isModalOpen: false,
      isDarkMode: false, // 添加 isDarkMode 状态
    };
  },
  mounted() {
    // 检查 localStorage 并应用用户之前选择的模式
    if (localStorage.getItem('theme') === 'dark') {
      this.isDarkMode = true;
      document.documentElement.classList.add('dark');
    }
  },
  methods: {
    handleKeyDown(event) {
      if (event.key === 'Enter') {
        this.handleSubmit();  // 按下回车键时触发提交逻辑
      }
    },
    async handleSubmit() {
      var isEmpty = false;
      var button_element = document.getElementById('submit_button');

      // 检查空值
      for (const key in this.form) {
        var inputElement = document.getElementById(key);
        if (!this.form[key]) {
          inputElement.classList.add('border-red-500'); 
          isEmpty = true;
        } else {
          inputElement.classList.remove('border-red-500');
        }
      }

      if (isEmpty === false) {
        console.log('表单提交', this.form);
        button_element.disabled = true;
        button_element.innerHTML = `
<span>
  登录中
  <i aria-hidden="true" role="status" class="inline-block rounded-full animate-spin icon--loading align-middle" />
</span>`;

        const result = await this.$store.dispatch('login', {
          account: this.form.account,
          password: this.form.password,
        });

        if (result.success) {
          this.$router.push({ name: 'DashboardPage' });
        } else {
          this.showInfo('登录失败', result.error);
        }

        button_element.disabled = false;
        button_element.innerHTML = '登&nbsp;录';
      }
    },
    showInfo(title, content) {
      this.modalTitle=title;
      this.modalContent=content;
      this.openModal();
    },
    openModal() {
      this.isModalOpen = true;
    },
    handleCloseModal() {
      this.isModalOpen = false;
    },
    toggleDarkMode() {
      this.isDarkMode = !this.isDarkMode;
      if (this.isDarkMode) {
        document.documentElement.classList.add('dark');
        localStorage.setItem('theme', 'dark'); // 保存用户选择
      } else {
        document.documentElement.classList.remove('dark');
        localStorage.setItem('theme', 'light');
      }
    },
  }
};
</script>

<style scoped>
/* Dark mode switch fixed to the bottom-left corner */
.darkmode-switch {
  position: fixed;
  bottom: 10px;
  left: 20px;
  cursor: pointer;
}

</style>
